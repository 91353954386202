// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--1mkg7";
export var footerContainer = "footer-module--footer-container--1urTf";
export var footerInformation = "footer-module--footer-information--1qz7U";
export var footerAuthor = "footer-module--footer-author--e3vx_";
export var footerSns = "footer-module--footer-sns--1ofoj";
export var footerLinks = "footer-module--footer-links--2UKxr";
export var footerLinksItem = "footer-module--footer-links-item--RJBqP";
export var footerCopyright = "footer-module--footer-copyright--10pfy";
export var footerCopyrightLink = "footer-module--footer-copyright-link--2f8hD";
export var footerToTop = "footer-module--footer-to-top--3fFq2";
export var footerSearch = "footer-module--footer-search--1K7jV";