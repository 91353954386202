import React from 'react'
import { Colors } from '../../../const/colors'
import * as style from './headline.module.styl'

export const headlineType = {
  borderLeft: 'border-left'
} as const

type headlineType = typeof headlineType[keyof typeof headlineType]

interface IfProps {
  tag: string
  type: headlineType
  color?: Colors
  classes?: string[]
}

const headlineStyle = (type: headlineType) => {
  switch (type) {
    case headlineType.borderLeft:
      return style.headlineBorderLeft
  }
}

const Headline: React.FC<IfProps> = ({
  tag,
  type,
  color,
  classes,
  children
}) => {
  const Tag = tag
  const classNames = [...(classes || []), headlineStyle(type)]

  return (
    // @ts-ignore
    <Tag className={classNames.join(' ')} data-color={color}>
      {children}
    </Tag>
  )
}

Headline.defaultProps = {
  tag: 'h2'
}

export default Headline
