import { graphql, useStaticQuery } from 'gatsby'

export type SiteMetaData = {
  defaultTitle: string
  titleTemplate: string
  defaultDescription: string
  siteUrl: string
  defaultImage: string
  twitterAccount: string
  author: {
    name: string
    description: string
    icon: {
      url: string
      width: string
      height: string
    }
  }
  publisher: {
    name: string
    url: string
  }
  icon: {
    url: string
    width: string
    height: string
  }
  sns: { iconClasses: string[]; url: string }[]
  config: {
    ALGOLIA_APP_ID: string
    ALGOLIA_API_KEY: string
    ALGOLIA_INDEX_NAME: string
  }
}

type UseSiteMetaData = {
  site: {
    siteMetadata: SiteMetaData
  }
}

/** サイト情報 */
export const useSiteMetaData = (): SiteMetaData => {
  const { site } = useStaticQuery<UseSiteMetaData>(graphql`
    query Seo {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterAccount
          author {
            name
            description
            icon {
              url
              width
              height
            }
          }
          publisher {
            name
            url
          }
          icon {
            url
            width
            height
          }
          sns {
            iconClasses
            url
          }
          config {
            ALGOLIA_API_KEY
            ALGOLIA_APP_ID
            ALGOLIA_INDEX_NAME
          }
        }
      }
    }
  `)

  return site.siteMetadata
}
