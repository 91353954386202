import React from 'react'
import { colors } from '../../../const/colors'
import Headline, { headlineType } from '../../atoms/headline/Headline'

interface IfProps {
  title: string
}

const FooterHeadline: React.FC<IfProps> = ({ title }) => {
  return (
    <Headline
      tag="h3"
      type={headlineType.borderLeft}
      color={colors.white}
      classes={['ff-headline', 'typography-subhead', 'font-kerning']}
    >
      {title}
    </Headline>
  )
}

export default FooterHeadline
