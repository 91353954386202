import {
  BlogPosting,
  BreadcrumbList,
  Organization,
  Person,
  Thing,
  WebSite,
  WithContext
} from 'schema-dts'
import { SiteMetaData } from '../hooks/useSiteMetaData'

export default class StructuredData {
  #config: SiteMetaData

  constructor(config: SiteMetaData) {
    this.#config = config
  }

  /** ブログ情報 */
  public blog(): WithContext<WebSite> {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: this.#config.siteUrl,
      name: this.#config.defaultTitle,
      description: this.#config.defaultDescription,
      image: {
        '@type': 'ImageObject',
        url: this.#config.siteUrl + this.#config.icon.url,
        width: this.#config.icon.width,
        height: this.#config.icon.height
      },
      author: this.#author(),
      publisher: this.#publisher()
      // TODO: サイト内検索がないとリッチリザルトテストに合格しない
      // potentialAction: {
      //   '@type': 'SearchAction',
      //   target: '',
      //   'query-input': ''
      // }
    }
  }

  /**
   * パンくず
   * @param arr
   */
  public breadcrumbs(
    arr: { id: string; name: string }[]
  ): WithContext<BreadcrumbList> {
    const _items: BreadcrumbList['itemListElement'] = arr.map(
      ({ id, name }, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        item: {
          '@id': id,
          name: name
        }
      })
    )

    const breadcrumbs: BreadcrumbList['itemListElement'] = [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': this.#config.siteUrl,
          name: this.#config.defaultTitle
        }
      },
      ..._items
    ]

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs
    }
  }

  /**
   * 記事詳細
   * @param headline
   * @param description
   * @param link
   * @param dateGmt
   * @param modifiedGmt
   * @param image
   */
  public article(
    headline: string,
    description: string,
    link: string,
    dateGmt: string,
    modifiedGmt: string,
    image: { url: string; width: number; height: number }
  ): WithContext<BlogPosting> {
    const context: WithContext<BlogPosting> = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      headline,
      description,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': link
      },
      datePublished: dateGmt,
      dateModified: modifiedGmt,
      author: this.#author(),
      publisher: this.#publisher()
    }

    if (image.url !== '') {
      context.image = {
        '@type': 'ImageObject',
        url: image.url,
        width: image.width.toString(),
        height: image.height.toString()
      }
    }

    return context
  }

  public archive(link: string, name: string): WithContext<Thing> {
    return {
      '@context': 'https://schema.org',
      '@type': 'Thing',
      name,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': link
      }
    }
  }

  /** ブログ管理者 */
  #author(): Person {
    return {
      '@type': 'Person',
      name: this.#config.author.name,
      description: this.#config.author.description,
      url: this.#config.publisher.url,
      image: {
        '@type': 'ImageObject',
        url: this.#config.siteUrl + this.#config.author.icon.url,
        width: this.#config.author.icon.width,
        height: this.#config.author.icon.height
      },
      sameAs: this.#config.sns.map((s) => s.url)
    }
  }

  /** ブログ発行者 */
  #publisher(): Organization {
    return {
      '@type': 'Organization',
      name: this.#config.publisher.name,
      url: this.#config.publisher.url,
      logo: this.#config.siteUrl + this.#config.author.icon.url
    }
  }
}
