import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect } from 'react'
import { FooterQueryQuery } from '../../../../types/graphql-types'
import { colors } from '../../../const/colors'
import { spaceTopDefault } from '../../../const/space'
import { useSiteMetaData } from '../../../hooks/useSiteMetaData'
import { joinSpace } from '../../../libs/utils'
import LinkButtonDefault from '../../atoms/buttons/LinkButtonDefault'
import FooterHeadline from '../../molecules/footer/FooterHeadline'
import * as styles from './footer.module.styl'
import animejs from 'animejs'

interface IfProps {}

const query = (): FooterQueryQuery => {
  return useStaticQuery<FooterQueryQuery>(graphql`
    query FooterQuery {
      allWpCategory(
        filter: { count: { gte: 1 } }
        sort: { fields: [count], order: [DESC] }
      ) {
        nodes {
          id
          slug
          name
          uri
        }
      }
      allWpTag(
        filter: { count: { gte: 1 } }
        sort: { fields: [count], order: [DESC] }
      ) {
        nodes {
          id
          name
          slug
          uri
        }
      }
    }
  `)
}

// ウィンドウスクロール用要素取得
let windowElem: HTMLElement | Element

const scrollToTop = (): void => {
  animejs({
    targets: windowElem,
    scrollTop: 0,
    delay: 100,
    duration: 300,
    easing: 'easeInOutSine'
  })
}

const Footer: React.FC<IfProps> = () => {
  const { allWpCategory, allWpTag } = query()
  const { publisher, sns } = useSiteMetaData()

  useEffect(() => {
    windowElem = ((): HTMLElement | Element => {
      if ('scrollingElement' in document) {
        return document.scrollingElement as Element
      }

      // Fallback for legacy browsers
      if (navigator.userAgent.toLocaleLowerCase().indexOf('webKit') > -1) {
        return document.body
      }

      return document.documentElement
    })()
  })

  return (
    <footer className={['apps', styles.footer].join(' ')}>
      <div
        className={[
          'pt-8',
          'pb-8',
          'apps-container',
          styles.footerContainer
        ].join(' ')}
      >
        <button
          type="button"
          className={[styles.footerToTop].join(' ')}
          onClick={() => scrollToTop()}
        >
          <i className={['fas', 'fa-chevron-up'].join(' ')} />
        </button>

        <Link className={joinSpace([styles.footerSearch])} to={'/search'}>
          {' '}
          <i className={joinSpace(['fas', 'fa-search'])} />{' '}
        </Link>

        {/* Author & Categories */}
        <div className={styles.footerInformation}>
          <div>
            <FooterHeadline title="Author" />
            <h3 className={[spaceTopDefault, 'ff-headline'].join(' ')}>
              {'Nobuyuki Kondo'}
            </h3>
            <div className={'pt-2'}>
              <ul className={[styles.footerSns].join(' ')}>
                {sns.map(({ iconClasses, url }) => {
                  return (
                    <li key={url}>
                      <a href={url} target="_blank" rel="noreferrer">
                        <i className={[...iconClasses].join(' ')} />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className={'s-pt-8'}>
            <FooterHeadline title="Categories" />
            <nav>
              <ul className={styles.footerLinks}>
                {allWpCategory.nodes.map(({ id, uri, name }) => {
                  if (name == null) return
                  return (
                    <li key={id} className={styles.footerLinksItem}>
                      <LinkButtonDefault
                        name={name}
                        target={uri as string}
                        color={colors.white}
                      />
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>

        {/* Tags */}
        <div className={`pt-8`}>
          <FooterHeadline title="Tags" />
          <nav>
            <ul className={styles.footerLinks} data-direction="between">
              {allWpTag.nodes.map(({ id, uri, name }) => {
                if (name == null) return
                return (
                  <li key={id} className={styles.footerLinksItem}>
                    <LinkButtonDefault
                      name={name}
                      target={uri as string}
                      color={colors.white}
                    />
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>

        {/* copyright */}
        <div className="pt-8">
          <small
            className={['typography-copy', styles.footerCopyright].join(' ')}
          >
            {`\u00A9`}{' '}
            <a
              href={publisher.url}
              target="_blank"
              rel="noreferrer"
              className={styles.footerCopyrightLink}
            >
              {publisher.name}
            </a>{' '}
            {`All Rights Reserved.`}
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
