/**
 * スペース区切りにする
 * @param arr
 */
export const joinSpace = (arr: string[]): string => {
  return arr.join(' ')
}

/**
 * アーカイブの<title>タグ
 * @param name
 * @param currentPage
 */
export const archiveTitle = (name: string, currentPage: number): string => {
  const t = `${name}の一覧`
  if (currentPage === 1) return t
  return `${t} | ${currentPage}ページ目`
}

/**
 * タグと改行の除去
 * @param str
 */
export const removeTagsAndLb = (str?: string): string | undefined => {
  if (str == null) return
  return str.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').replace(/[\n\r]/g, '')
}
