import { Link } from 'gatsby'
import React from 'react'
import { Colors } from '../../../const/colors'
import * as styles from './link-button-default.module.styl'

interface IfProps {
  target: string
  name: string
  color: Colors
  classes?: string[]
}

const LinkButtonDefault: React.FC<IfProps> = ({
  target,
  name,
  classes,
  color
}) => {
  return (
    <Link
      to={target}
      className={[styles.linkButton, ...(classes || [])].join(' ')}
      data-color={color}
    >
      {name}
    </Link>
  )
}

export default LinkButtonDefault
