import { useLocation } from '@reach/router'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BlogPosting, BreadcrumbList, Thing, WithContext } from 'schema-dts'
import { useSiteMetaData } from '../../hooks/useSiteMetaData'
import StructuredData from '../../const/structuredData'

interface IfProps {
  title?: string
  description?: string
  ogType?: string
  image?: string
  structuredDataBreadcrumbs?: WithContext<BreadcrumbList>
  structuredDataArticle?: WithContext<BlogPosting>
  structuredDataArchive?: WithContext<Thing>
}

const HeadMeta: React.FC<IfProps> = ({
  title,
  description,
  ogType,
  image,
  structuredDataBreadcrumbs,
  structuredDataArticle,
  structuredDataArchive
}) => {
  if (ogType == null) ogType = 'website'

  const config = useSiteMetaData()
  const sd$ = new StructuredData(config)

  const sd: object[] = [sd$.blog()]

  const {
    defaultTitle,
    defaultDescription,
    titleTemplate,
    siteUrl,
    defaultImage,
    twitterAccount,
    author
  } = config

  const { pathname } = useLocation()

  const seo = {
    title: title || defaultTitle,
    description: description || (defaultDescription as string),
    url: siteUrl + pathname,
    image: siteUrl + (image || defaultImage)
  }

  const prefix = ['og: https://ogp.me/ns#']
  prefix.push(`${ogType}: https://ogp.me/ns/${ogType}#`)

  return (
    <Helmet
      title={title || undefined}
      defaultTitle={defaultTitle as string}
      titleTemplate={titleTemplate as string}
      defer={false}
    >
      {/* head句に記載できないため修正されるまでhtml句に記述 */}
      <html lang="ja" prefix={prefix.join(' ')} />
      {/* meta */}
      <link rel="canonical" href={seo.url} />
      <meta name="author" content={author.name} />
      <meta name="description" content={seo.description} />
      {/* OGP */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content={defaultTitle as string} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={twitterAccount} />
      {/* 構造化データ */}
      <script type="application/ld+json">{JSON.stringify(sd)}</script>
      {structuredDataBreadcrumbs && (
        <script type="application/ld+json">
          {JSON.stringify(structuredDataBreadcrumbs)}
        </script>
      )}{' '}
      {structuredDataArticle && (
        <script type="application/ld+json">
          {JSON.stringify(structuredDataArticle)}
        </script>
      )}{' '}
      {structuredDataArchive && (
        <script type="application/ld+json">
          {JSON.stringify(structuredDataArchive)}
        </script>
      )}
    </Helmet>
  )
}

export default HeadMeta
